import { ref } from "vue";
import { defineStore } from "pinia";

export const usePreviewStore = defineStore("preview", () => {
  const isPreviewing = ref(false);
  function show() {
    isPreviewing.value = true;
  }
  function hide() {
    isPreviewing.value = false;
  }

  return { isPreviewing, show, hide };
});
