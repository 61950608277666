import { ref } from "vue";
import { defineStore } from "pinia";

export const useFirebaseStore = defineStore("firebase", () => {
  const oobCode = ref("");
  function setOobCode(value: string) {
    oobCode.value = value;
  }

  return { oobCode, setOobCode };
});
