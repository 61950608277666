import Swal from "sweetalert2";

export class AlertService {
  public static toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    color: "#f5f5f5",
    iconColor: "#f5f5f5",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  public static toastError(title: string, timer = 3000) {
    this.toast.fire({
      icon: "error",
      title,
      timer,
      background: "#dc3545",
    });
  }
  public static toastSuccess(title: string, timer = 3000) {
    this.toast.fire({
      icon: "success",
      title,
      timer,
      background: "#28a745",
    });
  }
  public static toastWarning(title: string, timer = 3000) {
    this.toast.fire({
      icon: "warning",
      title,
      timer,
      background: "#ffc107",
      color: "#555",
      iconColor: "#555",
      focusConfirm: true,
    });
  }
  public static toastWarningWaitClick(title: string) {
    this.toast.fire({
      icon: "warning",
      title,
      timer: undefined,
      background: "#ffc107",
      color: "#555",
      iconColor: "#555",
    });
  }
  public static toastLoadingImage() {
    this.toast.fire({
      // title: "aaaa",
      timer: undefined,
      background: "#2b2b2b",
      color: "#f5f5f5",
      html: `
      <div
        class="flex items-center gap-4"
      >
        <span
          class="border-4 border-white/20 border-t-purple-600 rounded-full w-10 h-10 animate-spin"
        ></span>
        <span class="text-light-0 font-medium">Enviando imagem</span>
      </div>
      `,
      didOpen: () => {},
    });
  }
  public static closeAll() {
    this.toast.close();
  }
}
