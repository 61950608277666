import { ApiRouter } from "./api";
import type { ApiRouterConsumerInterface } from "./model/apiConsumer.interface";

//@ts-ignore
const baseURL = import.meta.env.VITE_API;
//@ts-ignore
const baseURLAuth = import.meta.env.VITE_API_AUTH;
// const baseMockURL = "http://localhost:3000/";

export const Endpoint: ApiRouterConsumerInterface = {
  auth: {
    login: new ApiRouter({
      baseURL: baseURLAuth,
      routerOrigin: "login",
    }),
    registre: {
      account: new ApiRouter({
        baseURL: baseURLAuth,
        routerOrigin: "register-initial",
        activedMock: false,
        routerOriginMock: "registre",
      }),
      publicity: new ApiRouter({
        baseURL: baseURLAuth,
        routerOrigin: "response",
        activedMock: true,
        routerOriginMock: "registre",
      }),
    },
    recover: new ApiRouter({
      baseURL: baseURLAuth,
      routerOrigin: "response",
    }),
    validToken: new ApiRouter({
      baseURL: baseURL,
      routerOrigin: "api/user/valid",
    }),
  },
  preview: {
    list: new ApiRouter({
      baseURL,
      routerOrigin: "",
    }),
    createProject: new ApiRouter({
      baseURL,
      // routerOrigin: "post_template",
      routerOrigin: "api/project",
    }),
    projects: new ApiRouter({
      baseURL,
      // routerOrigin: "list",
      routerOrigin: "api/project",
    }),
  },
  buildPage: {
    wordVerifier: new ApiRouter({
      baseURL,
      routerOrigin: "**definir-rota**",
      // routerOrigin: "api/project",
    }),
    save: new ApiRouter({
      baseURL,
      // routerOrigin: "post_template",
      routerOrigin: "api/project",
    }),
    getTemplate: new ApiRouter({
      baseURL,
      // routerOrigin: "get_template",
      routerOrigin: "api/project",
    }),
  },
  domains: {
    getAllDomainsByUser: new ApiRouter({
      baseURL,
      // routerOrigin: "list_domain",
      routerOrigin: "api/domain",
    }),
    newDomain: new ApiRouter({
      baseURL,
      // routerOrigin: "post_domain",
      routerOrigin: "api/domain",
    }),
    toggleStatus: new ApiRouter({
      baseURL,
      // routerOrigin: "post_domain",
      routerOrigin: "api/domain",
    }),
    updateDomain: new ApiRouter({
      baseURL,
      // routerOrigin: "update_domain",
      routerOrigin: "api/domain",
    }),
    deleteDomain: new ApiRouter({
      baseURL,
      // routerOrigin: "delete_domain",
      routerOrigin: "api/domain",
    }),
  },
};
