import { ref } from "vue";
import { defineStore } from "pinia";

export const useLoadingService = defineStore("loading", () => {
  const stateLoading = ref(true);

  function getStateLoading() {
    return stateLoading.value;
  }

  function enableLoading() {
    stateLoading.value = true;
  }

  function disabledLoading() {
    stateLoading.value = false;
  }

  return { stateLoading, getStateLoading, enableLoading, disabledLoading };
});
