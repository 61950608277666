<script setup lang="ts">
import { onMounted, ref } from "vue";
import EyeAlt from "@/shared/components/icons/dark/EyeAlt.vue";
import Eye from "@/shared/components/icons/dark/Eye.vue";
import Close2 from "@/shared/components/icons/dark/Close2.vue";

const props = defineProps({
  label: String,
  id: String,
  errorMessage: String,
  type: { type: String, default: "text" },
  required: { type: Boolean, default: false },
  autocomplete: { type: String, default: "off" },
});
const model = defineModel();
const inputType = ref("text");
const showPassword = ref(false);

onMounted(() => {
  inputType.value = props.type;
  showPassword.value =
    props.type === "password" && inputType.value === "password";
});

function toggleType() {
  inputType.value =
    props.type === "password" && inputType.value === "password"
      ? "text"
      : props.type;
  showPassword.value =
    props.type === "password" && inputType.value === "password";
}
</script>
<template>
  <div class="relative">
    <input
      spellcheck="false"
      :type="inputType"
      class="peer h-14 w-full rounded-tiny p-xxxs font-poppins text-p5 text-dark-200 outline outline-1 -outline-offset-1 outline-light-600 duration-500 ease-in-out placeholder:text-transparent focus:!outline-primary-600"
      :class="{
        '!outline-red-600': errorMessage,
        'pr-12': type === 'password',
      }"
      :id="id"
      :placeholder="label"
      v-model="model"
      :required="required"
      :autocomplete="autocomplete"
    />
    <label
      :for="id"
      class="absolute left-3 -translate-y-[9px] cursor-text rounded-tiny bg-white px-quarck font-poppins text-p6 font-medium text-light-600 duration-500 ease-in-out peer-placeholder-shown:translate-y-4 peer-placeholder-shown:text-p5 peer-placeholder-shown:font-normal peer-placeholder-shown:text-light-600 peer-focus:-translate-y-[9px] peer-focus:text-p6 peer-focus:font-medium peer-focus:text-primary-600"
      :class="{ 'text-red-600': errorMessage }"
    >
      {{ label }}
    </label>
    <button
      class="absolute right-2 top-1/2 flex -translate-y-1/2 items-center justify-center fill-light-600 p-2 peer-focus:fill-primary-600"
      type="button"
      v-if="type === 'password' && !errorMessage"
      @click="toggleType"
    >
      <EyeAlt class="fill-inherit" v-if="showPassword" />
      <Eye class="fill-inherit" v-else />
    </button>
    <span
      v-if="errorMessage"
      class="absolute right-4 top-[18px] flex aspect-square w-5 items-center justify-center rounded-full bg-red-600"
    >
      <Close2 class="fill-light-0" :size="8" />
    </span>
    <p
      class="p-quarck pb-0 font-poppins text-p6 text-red-600"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
