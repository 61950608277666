import { onAuthStateChanged } from "firebase/auth";
import LogRocket from "logrocket";

import AuthService from "@/views/pages/Auth/Auth.service";

import router from "@/router";

const refreshToken = () => {
  onAuthStateChanged(AuthService.auth, (currentUser) => {
    if (currentUser) {
      if (!import.meta.env.DEV) {
        LogRocket.identify(currentUser.uid, {
          name: currentUser.displayName || "Sem nome",
          email: currentUser.email || "Sem email",
        });
      }

      currentUser
        .getIdToken(true)
        .then((token) => {
          localStorage.setItem("token", token);
        })
        .catch(() => {
          // console.log(err);
        });
    } else {
      const currentPath = router.options.history.location;

      const excludedPaths = [
        "/forgot-password",
        "/reset-password",
        "/auth/signin",
        "/dispositivo-incompativel",
        "/auth/validation",
      ];

      if (!excludedPaths.includes(currentPath)) {
        localStorage.setItem("continuePath", currentPath);
      }

      router.push("/auth/signin");
    }
  });

  return localStorage.getItem("token");
};

export default refreshToken;
