<script setup lang="ts">
defineProps({
  level: { type: Number, required: true },
});
const passwordLevels = {
  1: "Fraca",
  2: "Média",
  3: "Forte",
  4: "Segura",
};
</script>
<template>
  <div class="flex flex-col gap-3" v-if="level">
    <div class="w-full flex gap-2">
      <span
        class="h-[3px] w-full bg-light-200 rounded-full"
        :class="{
          '!bg-red-600': lvl <= level && level === 1,
          '!bg-orange-600': lvl <= level && level === 2,
          '!bg-blue-600': lvl <= level && level === 3,
          '!bg-green-600': lvl <= level && level === 4,
        }"
        v-for="lvl in 4"
        :key="lvl"
      ></span>
    </div>
    <div class="flex justify-between">
      <span class="font-poppins text-p6 text-light-700">
        Força da sua senha
      </span>
      <span
        class="font-poppins text-p6 font-semibold"
        :class="{
          '!text-red-600': level === 1,
          '!text-orange-600': level === 2,
          '!text-blue-600': level === 3,
          '!text-green-600': level === 4,
        }"
      >
        {{ passwordLevels[level] }}
      </span>
    </div>
  </div>
</template>
<style></style>
