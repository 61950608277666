import { createApp } from "vue";
import { createHead } from "@vueuse/head";

import LogRocket from "logrocket";

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

import App from "./App.vue";
import router from "./router";
import { pinia } from "./stores";

import "sweetalert2/dist/sweetalert2.min.css";
import "grapesjs/dist/css/grapes.min.css";

// import "@/assets/css/fuchs.ui-css/index.css";
import "@gleissonneves/liz-design-token";
import "@/assets/css/tokens.css";
import "@/assets/css/base.css";
import "@/assets/css/main.css";

const app = createApp(App);
const head = createHead();

if (!import.meta.env.DEV) {
  LogRocket.init(import.meta.env.VITE_LOGROCKET_ID, {
    network: {
      requestSanitizer: (request) => {
        request.headers["Authorization"] = "";
        if (request.body) {
          const body = JSON.parse(request.body);
          body.password = "";
          request.body = JSON.stringify(body);
        }
        return request;
      },
      responseSanitizer: (response) => {
        if (response.body) {
          const body = JSON.parse(response.body);
          body.idToken = "";
          body.refreshToken = "";
          body.expiresIn = "";
          response.body = JSON.stringify(body);
        }
        return response;
      },
    },
  });
}

app.component("EasyDataTable", Vue3EasyDataTable);
app.use(pinia);
app.use(router);
app.use(head);
app.mount("#app");
