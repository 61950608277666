import type { ApiRouterInterface } from "./model/api.interface";

export class ApiRouter {
  private readonly baseURL: string;
  private readonly routerOrigin: string;
  private readonly activedMock?: boolean;
  private readonly baseMockURL?: string;
  private readonly routerOriginMock?: string;

  constructor(setting: ApiRouterInterface) {
    this.baseURL = setting.baseURL;
    this.routerOrigin = setting.routerOrigin;
    this.activedMock = setting.activedMock;
    this.baseMockURL = setting.baseMockURL;
    this.routerOriginMock = setting.routerOriginMock;
  }

  public get url(): string {
    if (this.usedModk()) {
      return `${this.baseMockURL}${this.routerOriginMock}`;
    }

    return this.baseURL + this.routerOrigin;
  }

  private usedModk(): boolean {
    return this.activedMock ? true : false;
  }
}
