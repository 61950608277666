import { ref } from "vue";
import { defineStore } from "pinia";

interface User {
  id: string;
  name: string;
  email: string;
}

export const useUserStore = defineStore("user", () => {
  const data = ref<User>({} as User);
  function setData(userData: User) {
    data.value = userData;
  }
  return { data, setData };
});
