import { ref } from "vue";
import { defineStore } from "pinia";

type SmartPlayerProps = {
  [x: string]: {
    player: object;
  };
};
interface updateListComponentTypeName {
  componentTypeName: string;
  action: "add" | "remove";
}
export const useComponentsStore = defineStore("components", () => {
  const smartPlayers = ref<SmartPlayerProps[]>([]);
  const listOfComponentsAlreadyAddedByTypeName = ref<string[]>([]);

  function findPositionTypeNameInList(componentTypeName: string) {
    return listOfComponentsAlreadyAddedByTypeName.value.findIndex(
      (item) => item === componentTypeName
    );
  }
  function updateListOfComponentsAlreadyAddedByTypeName({
    componentTypeName,
    action,
  }: updateListComponentTypeName) {
    const index = findPositionTypeNameInList(componentTypeName);
    switch (action) {
      case "add":
        if (index < 0)
          listOfComponentsAlreadyAddedByTypeName.value.push(componentTypeName);
        // TODO: Show feedback if it's false
        break;
      case "remove":
        if (index >= 0)
          listOfComponentsAlreadyAddedByTypeName.value.splice(index, 1);
        // TODO: Show feedback if it's false
        break;

      default:
        break;
    }
    // Object.assign(listOfComponentsAlreadyAddedByTypeName.value, component);
  }
  function setSmartPlayers(player: SmartPlayerProps) {
    Object.assign(smartPlayers.value, player);
  }

  return {
    smartPlayers,
    setSmartPlayers,
    listOfComponentsAlreadyAddedByTypeName,
    findPositionTypeNameInList,
    updateListOfComponentsAlreadyAddedByTypeName,
  };
});
