<script setup lang="ts">
import { computed, type PropType } from "vue";
import {
  FilePlus,
  Plus,
  Settings,
  Copy,
  Pen,
  ArrowRight,
  Trash2,
  Minus,
  Shield,
  Check,
  AlertTriangle,
} from "lucide-vue-next";

import type { RouterLinkTo } from "@/shared/types";

const props = defineProps({
  type: {
    type: String as PropType<"link" | "button" | "submit" | "reset">,
    default: "link",
  },
  icon: {
    type: String as PropType<
      | "new-page"
      | "plus"
      | "minus"
      | "settings"
      | "copy"
      | "pen"
      | "arrow-right"
      | "trash"
      | "shield"
      | "alert-triangle"
      | "check"
    >,
  },
  text: { type: String, default: "Texto do botão" },
  to: {
    type: [String, Object] as PropType<string | RouterLinkTo>,
  },
  href: String,
  target: String,
  useObject: Boolean,
  toName: {
    type: String,
  },
  toId: {
    type: String,
  },
  variant: {
    type: String as PropType<"primary" | "secundary" | "outline" | "only">,
    default: "primary",
  },
  size: {
    type: String as PropType<
      "extra-large" | "large" | "medium" | "small" | "extra-small"
    >,
    default: "small",
  },
  mode: {
    type: String as PropType<"normal" | "destructive" | "success">,
    default: "normal",
  },
  loading: Boolean,
  disabled: Boolean,
  full: Boolean,
  semibold: Boolean,
});
const emit = defineEmits(["click"]);

const componentType = computed(() => {
  return props.to ? "router-link" : props.href ? "a" : "button";
});
const componentProps = computed(() => {
  return {
    ...(props.to
      ? {
          to: props.useObject
            ? { name: props.toName, params: { id: props.toId } }
            : props.to,
          disabled: props.disabled,
        }
      : props.href
      ? { href: props.href, target: props.target }
      : { type: props.type, disabled: props.disabled }),
  };
});

function handleClick(event: Event) {
  if (props.disabled) {
    event.preventDefault();
  } else {
    emit("click", event);
  }
}
</script>
<template>
  <component
    :is="componentType"
    v-bind="componentProps"
    @click="handleClick"
    class="flex w-fit gap-2 items-center font-semibold font-poppins rounded-tiny text-center transition disabled:cursor-not-allowed"
    :class="{
      '!w-full': full,

      // Mode normal
      'bg-primary-600 text-light-0 disabled:text-light-0/40 hover:bg-primary-700/90 active:bg-primary-700/80 disabled:bg-light-300/30':
        variant === 'primary' && mode === 'normal',
      'bg-primary-400/10 text-primary-600 disabled:text-light-400/40 hover:bg-primary-500/10 active:bg-primary-600/10  disabled:bg-light-200/10':
        variant === 'secundary' && mode === 'normal',
      'border border-light-300/20 bg-transparent text-primary-600 shadow-none hover:border-primary-500/10 hover:bg-primary-400/10 active:border-primary-600/10 active:bg-primary-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:text-light-400/40':
        variant === 'outline' && mode === 'normal',
      'border-none bg-transparent !font-medium text-light-300 shadow-none hover:text-primary-600  hover:bg-primary-400/10 active:bg-primary-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:text-light-400/40':
        variant === 'only' && mode === 'normal',

      // Mode destructive
      'bg-red-600 text-light-0 disabled:text-light-0/40 hover:bg-red-700/90 active:bg-red-700/80 disabled:bg-light-300/30':
        variant === 'primary' && mode === 'destructive',
      'bg-red-400/10 text-red-600 disabled:text-light-400/40 hover:bg-red-500/10 active:bg-red-600/10  disabled:bg-light-200/10':
        variant === 'secundary' && mode === 'destructive',
      'border border-light-300/20 bg-transparent text-red-600 shadow-none hover:border-red-500/10 hover:bg-red-400/10 active:border-red-600/10 active:bg-red-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:text-light-400/40':
        variant === 'outline' && mode === 'destructive',
      'border-none bg-transparent !font-medium text-light-300 shadow-none hover:text-red-600  hover:bg-red-400/10 active:bg-red-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:text-light-400/40':
        variant === 'only' && mode === 'destructive',

      // Mode success
      'bg-green-600 text-light-0 disabled:text-light-0/40 hover:bg-green-700/90 active:bg-green-700/80 disabled:bg-light-300/30':
        variant === 'primary' && mode === 'success',
      'bg-green-400/10 text-green-600 disabled:text-light-400/40 hover:bg-green-500/10 active:bg-green-600/10  disabled:bg-light-200/10':
        variant === 'secundary' && mode === 'success',
      'border border-light-300/20 bg-transparent text-green-600 shadow-none hover:border-green-500/10 hover:bg-green-400/10 active:border-green-600/10 active:bg-green-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:text-light-400/40':
        variant === 'outline' && mode === 'success',
      'border-none bg-transparent !font-medium text-light-300 shadow-none hover:text-green-600  hover:bg-green-400/10 active:bg-green-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:text-light-400/40':
        variant === 'only' && mode === 'success',

      // Sizes
      'h-[72px] px-8 text-p3': size === 'extra-large',
      'h-14 px-6 text-p4': size === 'large',
      'h-12 px-6 text-p5': size === 'medium',
      'h-10 px-6 text-p6': size === 'small',
      'h-8 px-4 text-p7': size === 'extra-small',
    }"
  >
    <template v-if="loading">
      <span
        class="w-5 h-5 border-2 border-transparent border-t-slate-300 border-l-slate-300 rounded-full animate-spin"
      ></span>
    </template>
    <template v-if="!loading && icon && icon !== 'arrow-right'">
      <span class="icons">
        <Trash2 :size="20" v-if="icon === 'trash'" />
        <Pen :size="20" v-if="icon === 'pen'" />
        <Plus :size="20" v-if="icon === 'plus'" />
        <Minus :size="20" v-if="icon === 'minus'" />
        <FilePlus :size="20" v-if="icon === 'new-page'" />
        <Shield :size="20" v-if="icon === 'shield'" />
        <Settings :size="20" v-if="icon === 'settings'" />
        <Copy :size="18" v-if="icon === 'copy'" />
        <Check :size="20" v-if="icon === 'check'" />
        <AlertTriangle :size="20" v-if="icon === 'alert-triangle'" />
      </span>
    </template>
    <span class="whitespace-nowrap">
      {{ text }}
    </span>
    <template v-if="icon === 'arrow-right'">
      <span class="icons">
        <ArrowRight :size="20" v-if="icon === 'arrow-right'" />
      </span>
    </template>
  </component>
</template>
<style scoped></style>
