import { ref } from "vue";
import { defineStore } from "pinia";

export const useModalStore = defineStore("modal", () => {
  const isOpen = ref(false);
  const isOpenLibIcons = ref(false);
  const isOpenNotice = ref(false);
  const isOpenChecker = ref(false);
  const alreadyClosed = ref(false);
  const idIconComponent = ref("");
  function toggle() {
    isOpen.value = !isOpen.value;
  }
  function open() {
    isOpen.value = true;
  }
  function close() {
    isOpen.value = false;
  }

  function openLibIcons() {
    isOpenLibIcons.value = true;
  }
  function closeLibIcons() {
    isOpenLibIcons.value = false;
    idIconComponent.value = "";
  }
  function setIdIconComponent(elementId) {
    idIconComponent.value = elementId;
  }

  function openNotice() {
    isOpenNotice.value = true;
  }
  function closeNotice() {
    isOpenNotice.value = false;
  }

  function openChecker() {
    isOpenChecker.value = true;
  }
  function closeChecker() {
    isOpenChecker.value = false;
  }

  function setClosedPlan() {
    alreadyClosed.value = true;
  }

  return {
    alreadyClosed,
    setClosedPlan,
    isOpen,
    toggle,
    open,
    close,
    openLibIcons,
    closeLibIcons,
    isOpenLibIcons,
    setIdIconComponent,
    idIconComponent,
    isOpenNotice,
    openNotice,
    closeNotice,
    isOpenChecker,
    openChecker,
    closeChecker,
  };
});
