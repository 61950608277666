import { ref } from "vue";
import { defineStore } from "pinia";

interface TemplateConfig {
  isTemplate: boolean;
  isPublished: boolean;
  title: string; // Não é opcional, apenas para não dar erro.
  description: string;
  author?: {
    id: string;
    name: string;
  };
  type: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  isPremium: boolean;
  isFree: boolean;
  urlThumbnail: string;
}

export const useTemplateConfigStore = defineStore("template", () => {
  const common = ref<TemplateConfig>({} as TemplateConfig);

  function setCommon(config: TemplateConfig) {
    common.value = config;
  }
  function clean() {
    common.value = {} as TemplateConfig;
  }
  return {
    common,
    setCommon,
    clean,
  };
});
