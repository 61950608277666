import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";

export default class ApiService {
  private static header: AxiosRequestConfig["headers"];
  private static config: AxiosRequestConfig;
  public static apiClient: AxiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
      ...ApiService.header,
    },
    ...ApiService.config,
  });

  public static setheader(values: AxiosRequestConfig["headers"]) {
    ApiService.header = values;
  }

  public static setConfigApiClient(values: AxiosRequestConfig) {
    ApiService.config = values;
  }

  public static delete<T>(
    endpoint: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    return ApiService.apiClient.delete(endpoint, config);
  }

  public static get<T>(
    endpoint: string,
    queryParams: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    return ApiService.apiClient.get(endpoint, queryParams);
  }

  public static put<T>(
    endpoint: string,
    body: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    return ApiService.apiClient.put(endpoint, body, config);
  }

  public static post<T>(
    endpoint: string,
    body: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    return ApiService.apiClient.post(endpoint, body, config);
  }

  /* Interceptor */
  public static addRequestInterceptor(
    onFulfilled?: () =>
      | InternalAxiosRequestConfig<any>
      | Promise<InternalAxiosRequestConfig<any>>,
    onRejected?: () => any
  ): number {
    return ApiService.apiClient.interceptors.request.use(
      onFulfilled,
      onRejected
    );
  }

  public static removeRequestInterceptor(interceptorId: number): void {
    ApiService.apiClient.interceptors.request.eject(interceptorId);
  }

  /* Interceptor RESPONSE */
  public static addResponseInterceptor(
    onFulfilled?: () => AxiosResponse<any> | Promise<AxiosResponse<any>>,
    onRejected?: () => any
  ): number {
    return ApiService.apiClient.interceptors.response.use(
      onFulfilled,
      onRejected
    );
  }

  public static removeResponseInterceptor(interceptorId: number): void {
    ApiService.apiClient.interceptors.response.eject(interceptorId);
  }
}
