import { ref } from "vue";
import { defineStore } from "pinia";

import type { GetImportFontParams } from "@/shared/service/FontsService/FontsService";
import type {
  OptionValue,
  PixelsValue,
} from "@/shared/components/Select/Select.type";
import type { DomainSelect } from "@/views/pages/Domains/Domains.type";

interface CommonConfig {
  projectId: string;
  projectName: string;
  projectDomain: DomainSelect;
  urlThumbnail: string;
  // resourceDomain: string;
  // isRootProject: boolean;
  appIcon?: string;
  favicon: string;
  resourceName: string;
  slug: string;
  isRoot: boolean;
  lang: OptionValue;
  robotsNoIndex: boolean;
  externalCss?: string[];
}
interface ResourceConfig {
  externalCss: {
    fonts: string[];
    styles: string[];
    scripts: string[];
  };
}
export interface SEOConfig {
  [x: string]: {
    title: string;
    description: string;
    social: {
      title: string;
      description: string;
      cover: string;
    };
  };
}
export interface TemplateConfig {
  isTemplate: boolean;
  isPrivate: boolean;
  category: OptionValue;
}

export interface WordsCheckedConfig {
  danger: string[];
  warning: string[];
}

export const useConfigStore = defineStore("config", () => {
  const common = ref<CommonConfig>({} as CommonConfig);
  const resource = ref<ResourceConfig>({} as ResourceConfig);
  const seo = ref<SEOConfig>({} as SEOConfig);
  const fonts = ref<GetImportFontParams[]>([]);
  const pixels = ref<PixelsValue[]>([]);
  const template = ref<TemplateConfig>({} as TemplateConfig);
  const firstAccess = ref(false);
  const redirectUrl = ref("");
  const blockRightButton = ref(false);
  // const wordsChecked = ref<WordsCheckedConfig>({} as WordsCheckedConfig);

  function setCommon(config: CommonConfig) {
    common.value = config;
  }
  function setResource(config: ResourceConfig) {
    resource.value = config;
  }
  function setBlockRightButton(isBlock: boolean) {
    blockRightButton.value = isBlock;
  }
  function getSEO(id: string) {
    return seo.value[id]
      ? seo.value[id]
      : {
          title: "",
          description: "",
          social: {
            title: "",
            cover: "",
            description: "",
          },
        };
  }
  function setSEO(config: SEOConfig) {
    seo.value = config;
  }
  function setFonts(selectedFonts: GetImportFontParams[]) {
    fonts.value = selectedFonts;
  }
  function setPixels(listPixels: PixelsValue[]) {
    pixels.value = listPixels;
  }
  function setTemplate(config: TemplateConfig) {
    template.value = config;
  }
  function clean() {
    common.value = {} as CommonConfig;
    seo.value = {} as SEOConfig;
    fonts.value = [];
    pixels.value = [];
    // Não está sendo usado.
    template.value = {} as TemplateConfig;
    redirectUrl.value = "";
    resource.value = {} as ResourceConfig;
    blockRightButton.value = false;
  }
  function setFirstAccess() {
    firstAccess.value = true;
  }
  function resetFirstAccess() {
    firstAccess.value = false;
  }
  function setRedirectUrl(value: string) {
    redirectUrl.value = value;
  }
  // function setWordsChecked(value: WordsCheckedConfig) {
  //   wordsChecked.value = value;
  // }
  return {
    common,
    resource,
    seo,
    setCommon,
    setResource,
    getSEO,
    setSEO,
    fonts,
    pixels,
    setPixels,
    setFonts,
    template,
    setTemplate,
    clean,
    firstAccess,
    setFirstAccess,
    resetFirstAccess,
    redirectUrl,
    setRedirectUrl,
    blockRightButton,
    setBlockRightButton,
    // wordsChecked,
    // setWordsChecked,
  };
});
