<script setup lang="ts">
import type { PropType } from "vue";
import {
  FilePlus,
  Plus,
  Settings,
  Copy,
  Pen,
  ArrowRight,
} from "lucide-vue-next";
import { AlertService } from "@/shared/service/SweetAlert/AlertService";

defineProps({
  type: {
    type: String as PropType<"link" | "button" | "submit" | "reset">,
    default: "link",
  },
  icon: {
    type: String as PropType<
      "new-page" | "plus" | "settings" | "copy" | "pen" | "arrow-right"
    >,
  },
  textToCopy: { type: String, default: "" },
  typeToCopy: {
    type: String as PropType<"page" | "template" | "text">,
  },
  text: { type: String, default: "Texto do botão" },
  to: {
    type: String,
    default: "/",
  },
  useObject: Boolean,
  toName: {
    type: String,
  },
  toId: {
    type: String,
  },
  variant: {
    type: String as PropType<"primary" | "only" | "secundary">,
  },
  textSize: {
    type: String as PropType<"small" | "normal">,
  },
  loading: Boolean,
  disabled: Boolean,
  full: Boolean,
  semibold: Boolean,
});

async function copyText(textToCopy, typeToCopy) {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(textToCopy);

      // Show different messages based on the typeToCopy
      switch (typeToCopy) {
        case "page":
          AlertService.toastSuccess("Link da página copiado!");
          break;
        case "template":
          AlertService.toastSuccess("Link de compartilhamento copiado!");
          break;
        case "text":
          AlertService.toastSuccess("Texto copiado!");
          break;
      }
    } catch (error) {
      AlertService.toastError("Houve um problema ao copiar o texto.");
    }
  } else {
    AlertService.toastError("Este recurso não é suportado neste navegador.");
  }
}
</script>
<template>
  <RouterLink
    :to="useObject ? { name: toName, params: { id: toId } } : to"
    class="flex w-fit items-center gap-2 rounded-sm bg-primary-600 px-4 py-4 text-center font-poppins text-p4 font-semibold text-light-0 transition hover:brightness-90 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:brightness-100"
    :class="{
      '!w-full flex justify-between': full,
      '!border-none !bg-transparent !text-primary-600 !shadow-none !py-2 font-medium':
        variant === 'only',
      '!px-4 !py-2 !text-white/80 !border-2 !border-solid !border-primary-600 !bg-primary-600/50 font-medium':
        variant === 'outline',
    }"
    v-if="type === 'link'"
    :disabled="disabled || loading"
  >
    <Plus :size="24" v-if="icon === 'plus'" />
    <FilePlus :size="24" v-if="icon === 'new-page'" />
    <Settings :size="24" v-if="icon === 'settings'" />
    <Pen :size="18" v-if="icon === 'pen'" />

    <span
      class="text-base font-medium"
      :class="{
        '!text-sm': variant === 'outline' || textSize === 'small',
        '!font-semibold': semibold,
      }"
      >{{ text }}</span
    >
  </RouterLink>
  <button
    class="flex w-fit items-center gap-2 rounded-sm bg-primary-600 px-4 py-4 text-center font-poppins text-p4 font-semibold text-light-0 transition hover:brightness-90 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:brightness-100"
    :class="{
      '!w-full flex justify-between': full,
      '!border-none !bg-transparent !text-primary-600 !shadow-none !py-2 font-medium':
        variant === 'only',
      '!px-4 !py-2 !text-white/80 !border-2 border-solid !border-primary-600 !bg-primary-600/50 font-medium':
        variant === 'outline',
    }"
    :type="type"
    :disabled="disabled"
    @click="textToCopy !== '' && copyText(`${textToCopy}`, typeToCopy)"
    v-else
  >
    <span
      class="flex aspect-square w-6 animate-spin items-center justify-center rounded-full bg-conic-spin"
      v-if="loading"
    >
      <span class="w-4 aspect-square rounded-full bg-primary-600"></span
    ></span>
    <span class="icons" v-if="!loading && !!icon && icon !== 'arrow-right'">
      <Plus :size="24" v-if="icon === 'plus'" />
      <FilePlus :size="24" v-if="icon === 'new-page'" />
      <Settings :size="24" v-if="icon === 'settings'" />
      <Copy :size="18" v-if="icon === 'copy'" />
    </span>
    <span
      v-if="!loading"
      class="text-base font-medium whitespace-nowrap"
      :class="{
        '!text-sm': variant === 'outline',
        '!font-semibold': semibold,
      }"
      >{{ text }}</span
    >
    <span class="icons" v-if="icon === 'arrow-right'">
      <ArrowRight :size="24" v-if="icon === 'arrow-right'" />
    </span>
  </button>
</template>
<style scoped></style>
