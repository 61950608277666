import { createPinia } from "pinia";

export const pinia = createPinia();

export * from "./config";
export * from "./template";
export * from "./preview";
export * from "./sidebar";
export * from "./tab";
export * from "./user";
export * from "./plan";
export * from "./validation";
export * from "./modal";
export * from "./components";
export * from "./badge";
export * from "./FuchsLoadingService";
export * from "./firebase";
