<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
// import { verifyPasswordResetCode, getAuth } from "firebase/auth";
import AuthService from "@/views/pages/Auth/Auth.service";
import { useRouter, useRoute } from "vue-router";
import { useFirebaseStore } from "@/stores";
import { AlertService } from "@/shared/service/SweetAlert/AlertService";

const query = ref<Query>();

const router = useRouter();
const route = useRoute();
const firebaseStore = useFirebaseStore();
const baseURL = ref("");

interface Query {
  apiKey: string;
  continueUrl: string;
  lang: string;
  mode: string;
  oobCode: string;
}

onBeforeMount(() => {
  baseURL.value = import.meta.env.VITE_BASEURL;
  //@ts-ignore
  query.value = route.query;
  const oobCode =
    typeof route.query.oobCode === "string" ? route.query.oobCode : "";
  const continueUrl =
    typeof route.query.continueUrl === "string" ? route.query.continueUrl : "/";

  if (oobCode && route.query.continueUrl) {
    AuthService.hangleVerifyPasswordResetCode(oobCode)
      .then(() => {
        firebaseStore.setOobCode(query.value?.oobCode || "");

        router.push({
          name: continueUrl.replace(baseURL.value, ""),
          replace: true,
        });
      })
      .catch((error) => {
        if (error.message.includes("auth/invalid-action-code")) {
          AlertService.toastError("Link inválido.");
        }
        router.push({ path: "/", replace: true });
      });
  }
});
</script>

<style scoped></style>
