import { createRouter, createWebHistory } from "vue-router";

import AuthService from "@/views/pages/Auth/Auth.service";
import { pinia, useValidationStore, UserValidData } from "@/stores";
const validationUser = useValidationStore(pinia);

import ForgotPassword from "@/views/pages/Auth/ForgotPassword.vue";
import ResetPassword from "@/views/pages/Auth/ResetPassword.vue";
import FirebaseAction from "@/views/pages/Auth/FirebaseAction.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/pages/Home/HomeView.vue"),
      meta: {
        title: "Início | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/paginas",
      name: "pages",
      component: () => import("@/views/pages/Pages/PagesView.vue"),
      meta: {
        title: "Minhas páginas | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/nova-pagina/:id?",
      name: "new-page",
      component: () => import("@/views/pages/Pages/NewPage/NewPage.vue"),
      meta: {
        title: "Nova página | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/builder/:id",
      name: "project-builder",
      component: () => import("@/views/pages/PageBuilder/PageBuilder.vue"),
      meta: {
        title: "Construtor de página | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/template-builder/:id",
      name: "template-builder",
      component: () => import("@/views/pages/PageBuilder/PageBuilder.vue"),
      meta: {
        title: "Construtor de template | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/templates",
      name: "templates",
      component: () => import("@/views/pages/Templates/TemplatesView.vue"),
      meta: {
        title: "Meus templates | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/novo-template/:id?",
      name: "novo-template",
      component: () =>
        import("@/views/pages/Templates/NewTemplate/NewTemplate.vue"),
      meta: {
        title: "Novo template | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/dominios",
      name: "dominios",
      component: () => import("@/views/pages/Domains/DomainsView.vue"),
      meta: {
        title: "Meus domínios | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/dominios/novo-dominio",
      name: "novo-dominio",
      component: () => import("@/views/pages/Domains/NewDomain/NewDomain.vue"),
      meta: {
        title: "Novo domínio | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/dominios/configuracoes/:id",
      name: "settings",
      component: () =>
        import("@/views/pages/Domains/NewDomain/SettingsDomain.vue"),
      meta: {
        title: "Configurar domínio | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/dominios/concluido",
      name: "complete",
      component: () =>
        import("@/views/pages/Domains/NewDomain/CompleteDomain.vue"),
      meta: {
        title: "Domínio configurado | Braip Pages",
        auth: true,
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
      meta: {
        title: "Recuperação de senha | Braip Pages",
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: ResetPassword,
      meta: {
        title: "Definir nova senha  | Braip Pages",
      },
    },
    {
      path: "/auth/validation",
      name: "firebase-action",
      component: FirebaseAction,
    },
    {
      path: "/auth/signin",
      name: "login",
      component: () => import("@/views/pages/Auth/Login/Login.vue"),
      meta: {
        title: "Login | Braip Pages",
      },
    },
    // {
    //   path: "/auth/signup",
    //   name: "signup",
    //   component: () => import("@/views/pages/Auth/SignUp/SignUp.vue"),
    //   meta: {
    //     title: "Criar conta | Braip Pages",
    //   },
    // },
    // {
    //   path: "/account-created",
    //   name: "account-created",
    //   component: () => import("@/views/pages/Auth/SignUp/AccountCreated.vue"),
    //   meta: {
    //     title: "Conta criada | Braip Pages",
    //   },
    // },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("@/views/pages/Error/NotFound.vue"),
      meta: {
        title: "Página não encontrada | Braip Pages",
      },
    },
    {
      path: "/dispositivo-incompativel",
      name: "incompatible-device",
      component: () => import("@/views/pages/Error/incompatibleDevice.vue"),
      meta: {
        title: "Dispositivo incompatível | Braip Pages",
        auth: true,
      },
    },
    (import.meta.env.dev || import.meta.env.staging) ?? {
      path: "/style-guide",
      name: "style-guide",
      component: () => import("@/views/pages/StyleGuide/index.vue"),
      meta: {
        title: "Style Guide | Braip Pages",
        auth: false,
      },
    },
  ],
});

router.beforeEach(async (to, _from, next) => {
  document.title = (to.meta.title as string) || "Braip Pages";

  if (to.meta?.auth) {
    const canAccess = await AuthService.checkToken()
      .then((res) => {
        if (res.status === 200) {
          const {
            activePlan,
            count,
            isPartner,
            isEasyEditor,
            lastTransaction,
            modal,
          } = res.data as UserValidData;
          //@ts-ignore
          validationUser.setData({
            activePlan,
            count,
            isPartner,
            isEasyEditor,
            lastTransaction,
            modal: modal instanceof Array ? undefined : modal,
          });
          return true;
        }
      })
      .catch(() => {
        return false;
      });

    if (!canAccess) {
      next("/auth/signin");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
