// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbl2HSo5SdV6n7aH_uJVcHgt0DUYZw0rw",
  authDomain: "voltk-login-homologacao.firebaseapp.com",
  projectId: "voltk-login-homologacao",
  storageBucket: "voltk-login-homologacao.appspot.com",
  messagingSenderId: "557728123177",
  appId: "1:557728123177:web:07568a8ebaa5df46a34f38",
};

// Initialize Firebase
const InitializeFirebaseApp = initializeApp(firebaseConfig);
export { InitializeFirebaseApp };
