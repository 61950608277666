import { ref } from "vue";
import { defineStore } from "pinia";

export const useTabStore = defineStore("tab", () => {
  const selected = ref("");
  function select(value) {
    selected.value = value;
  }

  return { selected, select };
});
