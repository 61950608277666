import { ref } from "vue";
import { defineStore } from "pinia";

import type { Plan } from "@/shared/types";

export const usePlanStore = defineStore("Z8k9s", () => {
  const data = ref<Plan>({} as Plan);
  function setData(value) {
    data.value = value;
  }

  return { data, setData };
});
