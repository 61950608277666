import { ref } from "vue";
import { defineStore } from "pinia";

interface Count {
  domains: number;
}
interface ActivePlan {
  isActive: boolean;
  id: number;
  title: string;
  description: string;
  amount: number;
  link: string;
  cover: string;
  features: string;
  isToggleableEditor: boolean;
  externalReference: string;
}
interface LastTransaction {
  id: number;
  status: string;
  statusDate: string;
  startSubscription: string;
  lastAmountPaid: number;
  dateLastPayment: string;
  dateNextPayment: string;
}
interface Header {
  text: string;
}
interface Content {
  cover: string;
  title: string;
  text: string;
}
interface Buttom {
  text: string;
  link: string;
}
interface Modal {
  closeable: boolean;
  header: Header;
  content: Content;
  buttom: Buttom;
}
export interface UserValidData {
  count: Count;
  activePlan: ActivePlan;
  lastTransaction: LastTransaction;
  modal?: Modal;
  isPartner: boolean;
  isEasyEditor: boolean;
}

export const useValidationStore = defineStore("user", () => {
  const data = ref<UserValidData>({} as UserValidData);
  function setData(userData: UserValidData) {
    data.value = userData;
  }
  return { data, setData };
});
