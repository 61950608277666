import refreshToken from "@/shared/service/RevogaToken";
import ApiService from "@api/apiService";
import { Endpoint } from "@api/apiConsumer";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  verifyPasswordResetCode,
  type User,
} from "firebase/auth";
import { InitializeFirebaseApp } from "./Auth.const";
export default class AuthService {
  public static service = ApiService.apiClient;
  public static auth = getAuth(InitializeFirebaseApp);

  public static hangleVerifyPasswordResetCode(oobCode: string) {
    return verifyPasswordResetCode(this.auth, oobCode);
  }

  public static loginAccountInApiDomain(user: User) {
    return ApiService.post(Endpoint.auth.login.url, {
      idToken: user.getIdToken(),
    });
  }

  public static logIn(data: { email: string; senha: string }) {
    const { email, senha: password } = data;
    return signInWithEmailAndPassword(AuthService.auth, email, password);
  }

  public static async signUp(data: {
    name: string;
    email: string;
    phone: string;
  }) {
    const response = await fetch(
      `${import.meta.env.VITE_API}webhook/free/subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshToken()}`,
        },
        body: JSON.stringify(data),
        mode: "cors",
      }
    );
    const result = await response.json();
    return result;
  }

  public static loginWithGoogle() {
    return AuthService.factoryLoginAccount(new GoogleAuthProvider());
  }

  public static loginWithFacebook() {
    return AuthService.factoryLoginAccount(new FacebookAuthProvider());
  }

  //
  public static loginWithApple() {
    return AuthService.factoryRegisteAccount(new FacebookAuthProvider());
  }

  private static factoryLoginAccount(
    provider: FacebookAuthProvider | GoogleAuthProvider
  ) {
    return signInWithPopup(AuthService.auth, provider);
  }

  private static factoryRegisteAccount(
    provider: FacebookAuthProvider | GoogleAuthProvider
  ) {
    return signInWithPopup(AuthService.auth, provider);
  }

  public static checkToken() {
    return ApiService.post(
      Endpoint.auth.validToken.url,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken()}`,
        },
      }
    );
  }
}
