import { ref } from "vue";
import { defineStore } from "pinia";

export const useBadgeStore = defineStore("badge", () => {
  const dangerCount = ref(0);
  const warningCount = ref(0);
  function setDangerCount(value) {
    dangerCount.value = value;
  }
  function setWarningCount(value) {
    warningCount.value = value;
  }

  return { dangerCount, setDangerCount, warningCount, setWarningCount };
});
