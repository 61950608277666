export const primary = {
  1000: "#160B32",
  900: "#2C1664",
  800: "#412097",
  700: "#572BC9",
  600: "#6D36FB",
  500: "#8A5EFC",
  400: "#A786FD",
  300: "#C5AFFD",
  200: "#E2D7FE",
  100: "#F4F0FF",
};
export const secondary = {
  1000: "#1F330A",
  900: "#3D6614",
  800: "#5C991F",
  700: "#7ACC29",
  600: "#99FF33",
  500: "#ADFF5C",
  400: "#C2FF85",
  300: "#D6FFAD",
  200: "#EBFFD6",
  100: "#F5FFEB",
};
export const red = {
  1000: "#4E0000",
  900: "#750000",
  800: "#9E0B0B",
  700: "#CA1515",
  600: "#FF2E2E",
  500: "#FE5F5F",
  400: "#FF9797",
  300: "#FFB8B8",
  200: "#FFD7D7",
  100: "#FFEFEF",
};
export const orange = {
  1000: "#774700",
  900: "#9C5E00",
  800: "#BA7002",
  700: "#D88100",
  600: "#FF9900",
  500: "#FFAF36",
  400: "#FFC46B",
  300: "#FFD493",
  200: "#FFE2B7",
  100: "#FFF3E0",
};
export const yellow = {
  1000: "#7D6A00",
  900: "#A18803",
  800: "#B99C03",
  700: "#DEBB00",
  600: "#FFD600",
  500: "#FFE24D",
  400: "#FFE871",
  300: "#FFED8C",
  200: "#FFF3B5",
  100: "#FFF9DE",
};
export const blue = {
  1000: "#094179",
  900: "#0A5FB4",
  800: "#1571CD",
  700: "#1D88F3",
  600: "#3399FF",
  500: "#6BB5FF",
  400: "#96CAFF",
  300: "#ADD6FF",
  200: "#C2E2FF",
  100: "#E9F4FF",
};
export const green = {
  1000: "#1B5B10",
  900: "#297A1B",
  800: "#399929",
  700: "#4BB639",
  600: "#5EC34D",
  500: "#72D761",
  400: "#8BE37C",
  300: "#A4F497",
  200: "#BFFFB4",
  100: "#E5FFE1",
};
export const pink = {
  1000: "#79005B",
  900: "#A61381",
  800: "#C22199",
  700: "#DF32B3",
  600: "#FF3FCE",
  500: "#FF68D9",
  400: "#FF8AE1",
  300: "#FFA0E7",
  200: "#FFBCEE",
  100: "#FFE5F9",
};
export const dark = {
  1100: "#9D9DAB",
  1000: "#8B8B98",
  900: "#797983",
  800: "#6D6D76",
  700: "#5B5B61",
  600: "#505057",
  500: "#414146",
  400: "#343438",
  300: "#252529",
  250: "#202227",
  200: "#19191E",
  150: "#0F1116",
  100: "#0D0D12",
  0: "#0B0B0E",
};
export const light = {
  1100: "#28283B",
  1000: "#434360",
  900: "#626284",
  800: "#777799",
  700: "#8383A4",
  600: "#9494B8",
  500: "#A9A9CC",
  400: "#BEBEDD",
  300: "#DCDCF0",
  200: "#EBEBF8",
  100: "#F7F7FC",
  0: "#FFFFFF",
};
export const gradientLinear = {
  purple: "linear-gradient(to top left, #F4F0FF00, #F4F0FF)",
  red: "linear-gradient(to top left, #FFEFEF00, #FFEFEF)",
  orange: "linear-gradient(to top left, #FFF3E000, #FFF3E0)",
  blue: "linear-gradient(to top left, #E9F4FF00, #E9F4FF)",
  green: "linear-gradient(to top left, #E5FFE100, #E5FFE1)",
  pink: "linear-gradient(to top left, #FFE5F900, #FFE5F9)",
  yellow: "linear-gradient(to top left, #FFF9DE00, #FFF9DE)",
  neutral: "linear-gradient(to top left, #FFFFFF00, #FFFFFF)",
};
