<script setup lang="ts">
import { light } from "@/assets/braip-theme/color";
import Close from "@/shared/components/icons/dark/Close.vue";

defineProps({
  title: String,
  closeable: Boolean,
});
const emit = defineEmits(["close-modal"]);
</script>
<template>
  <div
    class="min-h-full flex flex-col justify-center transition-all bg-dark-250 md:max-w-2xl shadow-lg relative rounded-tiny"
  >
    <header class="border-b-1 border-light-900/30">
      <div class="flex justify-between items-center p-8 pb-7">
        <h4 class="text-h8 font-medium text-light-0">{{ title }}</h4>
        <template v-if="closeable">
          <button class="hover:opacity-80" @click="emit('close-modal')">
            <Close :size="24" :fill="light[600]" />
          </button>
        </template>
      </div>
    </header>
    <main class="w-full p-8 pt-6 flex flex-col gap-6 transition-all">
      <slot></slot>
    </main>
  </div>
</template>

<style scoped></style>
