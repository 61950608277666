<script setup lang="ts">
import { ref, watch } from "vue";

import Loading from "@/shared/components/Loading/Loading.vue";
import ModalWrapper from "@/shared/components/ModalWrapper.vue";
import ModalContent from "@/shared/components/ModalContent.vue";

import { useLoadingService, useModalStore, useValidationStore } from "@/stores";
import ButtonComponent from "@/shared/components/ButtonComponent.vue";

const modal = useModalStore();
const loading = useLoadingService();
const validation = useValidationStore();
const modalValidationOpen = ref(false);

function handleCloseModalPlan() {
  modalValidationOpen.value = false;
  modal.setClosedPlan();
}

watch(validation, () => {
  modalValidationOpen.value = !!validation.data.modal;
});
</script>
<template>
  <loading :state="loading.stateLoading" />
  <router-view />
  <teleport to="#modals">
    <ModalWrapper
      :open="
        modalValidationOpen && !loading.stateLoading && !modal.alreadyClosed
      "
      @close="validation.data.modal?.closeable && handleCloseModalPlan()"
    >
      <ModalContent
        :title="validation.data.modal?.header.text"
        @close-modal="
          validation.data.modal?.closeable && handleCloseModalPlan()
        "
        :closeable="validation.data.modal?.closeable"
      >
        <div class="w-full flex flex-col gap-4">
          <img
            :src="
              validation.data.modal?.content.cover ||
              '/notification-illustra.svg'
            "
            class="h-64 aspect-square object-contain"
          />
          <p class="text-h7 text-center font-medium text-white/75">
            {{ validation.data.modal?.content.title }}
          </p>
          <div
            class="flex flex-col gap-2 text-p4 text-dark-800 text-justify"
            v-html="validation.data.modal?.content.text"
          ></div>
          <div class="flex justify-center">
            <ButtonComponent
              :text="validation.data.modal?.buttom.text"
              :href="validation.data.modal?.buttom.link"
              target="_blank"
              size="medium"
            />
          </div>
        </div>
      </ModalContent>
    </ModalWrapper>
  </teleport>
</template>
