import { ref } from "vue";
import { defineStore } from "pinia";

export const useSidebarStore = defineStore("sidebar", () => {
  const isShowingLeft = ref(true);
  const isShowingRight = ref(false);
  function toggleLeft() {
    isShowingLeft.value = !isShowingLeft.value;
  }
  function showLeft() {
    isShowingLeft.value = true;
  }
  function hideLeft() {
    isShowingLeft.value = false;
  }
  function showRight() {
    isShowingRight.value = true;
  }
  function hideRight() {
    isShowingRight.value = false;
  }

  return {
    isShowingLeft,
    isShowingRight,
    toggleLeft,
    showLeft,
    hideLeft,
    showRight,
    hideRight,
  };
});
